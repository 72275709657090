// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-events-event-detail-js": () => import("./../../../src/components/events/eventDetail.js" /* webpackChunkName: "component---src-components-events-event-detail-js" */),
  "component---src-components-gallery-portfolio-detail-js": () => import("./../../../src/components/gallery/portfolioDetail.js" /* webpackChunkName: "component---src-components-gallery-portfolio-detail-js" */),
  "component---src-components-products-product-detail-js": () => import("./../../../src/components/products/productDetail.js" /* webpackChunkName: "component---src-components-products-product-detail-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-porcelain-js": () => import("./../../../src/pages/all-porcelain.js" /* webpackChunkName: "component---src-pages-all-porcelain-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-look-page-template-js": () => import("./../../../src/templates/lookPageTemplate.js" /* webpackChunkName: "component---src-templates-look-page-template-js" */),
  "component---src-templates-series-detail-template-js": () => import("./../../../src/templates/seriesDetailTemplate.js" /* webpackChunkName: "component---src-templates-series-detail-template-js" */)
}

