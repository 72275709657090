const initialState = {
  products: [],
  productsPage: 1,
  galleryPage: 1,
  eventsPage: 1,
  lookFilterResults:[],
  typeFilterResults:[],
  searchResults: null
}

const FETCH_ALL_PRODUCTS = "FETCH_ALL_PRODUCTS"
const CHANGE_PRODUCTS_PAGE = "CHANGE_PRODUCTS_PAGE"
const CHANGE_GALLERY_PAGE = "CHANGE_GALLERY_PAGE"
const CHANGE_EVENTS_PAGE = "CHANGE_EVENTS_PAGE"
const COLLECT_LOOKFILTER_RESULTS = "COLLECT_LOOKFILTER_RESULTS"
const COLLECT_TYPEFILTER_RESULTS = "COLLECT_TYPEFILTER_RESULTS"
const COLLECT_SEARCH_RESULTS = "COLLECT_SEARCH_RESULTS"

export const fetchAllProducts = products => ({
  type: FETCH_ALL_PRODUCTS,
  products
})

export const changeProductsPage = page => ({
  type: CHANGE_PRODUCTS_PAGE,
  page
})

export const changeGalleryPage = page => ({
  type: CHANGE_GALLERY_PAGE,
  page
})

export const changeEventsPage = page => ({
  type: CHANGE_EVENTS_PAGE,
  page
})

export const collectLookfilterResults = lookFilterResults => ({
  type: COLLECT_LOOKFILTER_RESULTS,
  lookFilterResults
})

export const collectTypefilterResults = typeFilterResults => ({
  type: COLLECT_TYPEFILTER_RESULTS,
  typeFilterResults
})

export const collectSearchResults = result => ({
  type: COLLECT_SEARCH_RESULTS,
  result
})


export default function products(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_PRODUCTS:
      return { ...state, products: action.products }
    
    case CHANGE_PRODUCTS_PAGE: 
      return { ...state, productsPage: action.page }

    case CHANGE_GALLERY_PAGE:
      return { ...state, galleryPage: action.page }

    case CHANGE_EVENTS_PAGE:
      return { ...state, eventsPage: action.page }

    case COLLECT_LOOKFILTER_RESULTS: 
      return {...state, lookFilterResults: action.lookFilterResults}  

    case COLLECT_TYPEFILTER_RESULTS: 
      return {...state, typeFilterResults: action.typeFilterResults}
      
    case COLLECT_SEARCH_RESULTS: 
      return  {...state, searchResults: action.result}  
    default:
      return state
  }
}
